import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  GetWabaUserManagementAuditOptions,
  WabaUserManagementAuditCreateModel,
  WabaUserManagementAuditListOutModel,
  WabaUserManagementAuditUpdateModel,
} from '../domains/models/waba-user-management-audit.model';
import { Observable } from 'rxjs';
import { HelperService } from './helper/helper.service';

const params = new HttpParams().set('reset', 'true').set('locale', 'en');

@Injectable({
  providedIn: 'root',
})
export class WabaUserManagementAuditService {
  wabaManagementAuditRoute: string = `${environment.tppCsToolAPI.url}/v1/waba-management-audit`;

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getWabaManagementAudit(
    options: GetWabaUserManagementAuditOptions
  ): Observable<WabaUserManagementAuditListOutModel> {
    const { reset, user_fb_id, user_name, waba_id, valid, offset, limit } =
      options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      user_fb_id: user_fb_id,
      user_name: user_name,
      waba_id: waba_id,
      valid: valid,
      offset: offset,
      limit: limit,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<WabaUserManagementAuditListOutModel>(
      `${this.wabaManagementAuditRoute}/`,
      { params }
    );
  }

  createWabaManagementAudit(
    wabaManagementAuditCreate: WabaUserManagementAuditCreateModel
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.wabaManagementAuditRoute}/`,
      wabaManagementAuditCreate
    );
  }

  updateWabaManagementAudit(
    auditId: string,
    wabaUserManagementAuditUpdateModel: WabaUserManagementAuditUpdateModel
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${this.wabaManagementAuditRoute}/${auditId}`,
      wabaUserManagementAuditUpdateModel
    );
  }
}
