<div class="modal-header">
    <div class="d-inline-flex align-items-center">
        <h4 class="modal-title me-2">Partner information</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <label>Solution ID</label>
            <input type="text" class="form-control" formControlName="solutionId">
        </div>
    </form>
</div>
<div *ngIf="search && response && response.count >= 1" class="p-1">
    <ul class="list-unstyled px-2">
        <li class="d-flex align-items-center gap-2">
            <p class="mb-0">- <strong>Partner ID:</strong> {{ response?.partners?.[0]?.id }}</p>
            <a type="button" class="btn btn-outline-dark btn-sm" (click)="close()"
                [routerLink]="['/partners/', response?.partners?.[0]?.id]" [queryParams]="{ activeTab: '1' }">
                <i class="bi bi-arrow-right"></i>
            </a>
        </li>
        <li>
            <p>- <strong>Partner Name:</strong> {{ response?.partners?.[0]?.name }}</p>
        </li>
    </ul>

</div>
<div *ngIf="search && response && response.count == 0" class="p-1">
    <p class="ms-2 text-danger">No partner found by the provided solution ID</p>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" type="submit" [disabled]="!this.form.valid" (click)="confirm()">
        Check
    </button>
</div>