import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';

@Component({
  selector: 'app-channel-information',
  templateUrl: './channel-information.component.html',
  styleUrls: ['./channel-information.component.scss'],
})
export class ChannelInformationComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private hubService: HubService
  ) {}

  form: any;
  response: any;

  ngOnInit(): void {
    this.generateForm();
  }

  close(): void {
    this.helperService.dismissModal();
  }

  confirm(): void {
    this.helperService.showSpinner();
    this.hubService
      .getDeletedChannels(this.form.controls.phoneNumber.value)
      .subscribe({
        next: (res) => {
          this.response = res;
          this.helperService.hideSpinner(false);
        },
        error: (error) =>
          this.helperService.openErrorModal(
            'Fail to fetch channel information',
            error
          ),
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      phoneNumber: new FormControl('', [Validators.required]),
    });
  }
}
