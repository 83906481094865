import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';

@Component({
  selector: 'app-migrate-flows',
  templateUrl: './migrate-flows.component.html',
})
export class MigrateFlowsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private hubService: HubService
  ) {}

  form: any;
  response: any;
  error: boolean = false;

  ngOnInit(): void {
    this.generateForm();
  }

  close(): void {
    this.helperService.dismissModal();
  }

  confirm(): void {
    this.error = false;
    this.response = null;
    this.helperService.showSpinner();
    const body = {
      source_waba_account_id: this.form.controls['sourceWabaId'].value.trim(),
    };
    this.hubService
      .migrateFlows(
        this.form.controls['partnerId'].value.trim(),
        this.form.controls['destinationWabaId'].value.trim(),
        body
      )
      .subscribe({
        next: (res) => {
          this.response = res;
          this.helperService.hideSpinner(false);
        },
        error: (error) => {
          this.error = true;
          this.response = error;
          this.helperService.hideSpinner(false);
        },
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      partnerId: new FormControl('', [Validators.required]),
      sourceWabaId: new FormControl('', [Validators.required]),
      destinationWabaId: new FormControl('', [Validators.required]),
    });
  }
}
