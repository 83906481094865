<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Migrate flows from one WABA to another</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>Migration doesn't move the source Flows, it creates
                    copies of them with the same names in the destination WABA.
                </p>
            </div>
            <div class="col-12 p-2">
                <form [formGroup]="form">
                    <div class="form-group">
                        <label>Partner ID</label>
                        <input type="text" class="form-control" formControlName="partnerId">
                    </div>
                    <div class="form-group">
                        <label>Source WABA Account ID</label>
                        <input type="text" class="form-control" formControlName="sourceWabaId">
                    </div>
                    <div class="form-group">
                        <label>Destination WABA Account ID</label>
                        <input type="text" class="form-control" formControlName="destinationWabaId">
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12" *ngIf="response">
            <ngx-json-viewer *ngIf="!error" [json]="response"></ngx-json-viewer>
            <div *ngIf="error">
                <p class="text-danger">
                    Migration failed.
                </p>
                <ngx-json-viewer *ngIf="!error" [json]="response"></ngx-json-viewer>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" type="submit" (click)="confirm()">
            Submit
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="close()">
            Close
        </button>
    </div>
</div>