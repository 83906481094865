import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { UserModel } from 'src/app/core/domains/models/user.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { AuthUserModel } from 'src/app/core/domains/models/auth-user.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  subscriptions: Subscription[] = [];
  shouldShowSearch: boolean = false;
  stickyTop: boolean = false;
  form: FormGroup;
  user: UserModel;
  topBarData = [
    {
      route: '/phone',
      text: 'Integrations',
    },
    {
      route: '/action',
      text: 'Action',
    },
    {
      route: '/partners',
      text: 'Partners',
    },
    {
      route: '/known-issues',
      text: 'Known Issues',
    },
  ];

  private readonly TEAM_LEAD_INTERCOM_REVIEW_VIEW =
    'team-lead:intercom-review-view';
  teamLeadIntercomReviewView: boolean = false;

  private readonly TOS_VIEW = 'tos:view';
  tosView: boolean = false;

  private readonly REACT_VIEW = 'react:view';
  reactView: boolean = false;

  private readonly ADMIN_VIEW_PANEL = 'admin:view-panel';
  adminViewPanel: boolean = false;

  private readonly INTERCOM_PARTNER_ESCALATIONS_READ =
    'intercom:partner-escalations-read';
  intercomPartnerEscalationsRead: boolean = false;

  private readonly WABA_USER_MANAGEMENT_VIEW = 'waba-user-management:view';
  wabaUserManagementView: boolean = false;

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private userService: UserService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.generateForm();
    this.checkUserRoles();
    this.checkUser();
    const currentUrl = this.router.url;
    this.handleRouteChange(currentUrl);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleRouteChange(event.url);
      }
    });
  }

  private handleRouteChange(url: string): void {
    const splittedUrl = url.split('/');
    if (splittedUrl[2] == 'partner') {
      this.shouldShowSearch = true;
      this.stickyTop = true;
    } else {
      this.shouldShowSearch = false;
      this.stickyTop = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  logout(): void {
    this.authService.logout();
  }

  updateUserById(): void {
    this.helperService.showSpinner();
    const body = {
      phone_number: this.form.controls['phone_number'].value.trim(),
    };
    this.userService.updateUserById(this.user.id, body).subscribe({
      next: (res) => {
        this.user = res;
        this.sharedDataService.setUser(this.user);
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          'User updated successfully!'
        );
      },
      error: (err) =>
        this.helperService.openErrorModal(`Failed to update user data`, err),
    });
  }

  setSearchParameters(event) {
    this.router.navigate(['/phone'], {
      queryParams: {
        [event.searchType]: event.searchInput,
        searchType: 'single',
      },
    });
  }

  openModal(modalName): void {
    this.helperService.openModal(modalName);
  }

  hideNavigation() {
    const mobileWidthThreshold = 768;
    if (window.innerWidth < mobileWidthThreshold) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  private generateForm(phone_number: string = ''): void {
    this.form = this.formBuilder.group({
      phone_number: new FormControl(phone_number ?? '', Validators.required),
    });
  }

  private checkUserRoles() {
    this.subscriptions.push(
      this.sharedDataService.user$.subscribe((user: AuthUserModel) => {
        this.teamLeadIntercomReviewView = this.helperService.checkPermission(
          user,
          this.TEAM_LEAD_INTERCOM_REVIEW_VIEW
        );
        this.tosView = this.helperService.checkPermission(user, this.TOS_VIEW);
        this.reactView = this.helperService.checkPermission(
          user,
          this.REACT_VIEW
        );
        this.adminViewPanel = this.helperService.checkPermission(
          user,
          this.ADMIN_VIEW_PANEL
        );
        this.wabaUserManagementView = this.helperService.checkPermission(
          user,
          this.WABA_USER_MANAGEMENT_VIEW
        );
        this.intercomPartnerEscalationsRead =
          this.helperService.checkPermission(
            user,
            this.INTERCOM_PARTNER_ESCALATIONS_READ
          );
      })
    );
  }

  private checkUser() {
    this.subscriptions.push(
      this.sharedDataService.csToolUser$.subscribe((user: UserModel) => {
        if (user) this.user = user;
        if (user?.phone_number) {
          this.generateForm(user?.phone_number);
        }
      })
    );
  }
}
