<nav class="navbar navbar-expand-lg navbar-light bg-light"
  [ngStyle]="{ position: stickyTop ? 'sticky' : 'relative', top: stickyTop ? '0' : 'auto', 'z-index': 2 }">
  <a class="navbar-brand" routerLink="/">
    <img src="../../../assets/images/logo/360-logo-new-v2.png" width="40" height="40" alt="" />
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03"
    aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation"
    (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="{'bg-light': !isCollapsed}" id="navbarColor03"
    [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav me-auto">
      <li class="nav-item" *ngFor="let topBarItem of topBarData">
        <a class="nav-link py-3" [routerLink]="[topBarItem.route]" routerLinkActive="active" (click)="hideNavigation()">
          {{ topBarItem.text }}
        </a>
      </li>
      <li class="nav-item" *ngIf="tosView">
        <a class="nav-link py-3" [routerLink]="'/tos'" routerLinkActive="active" (click)="hideNavigation()">
          TOS
        </a>
      </li>
      <li class="nav-item" *ngIf="reactView">
        <a class="nav-link py-3" [routerLink]="'/react-view'" routerLinkActive="active" (click)="hideNavigation()">
          React
        </a>
      </li>
      <li class="nav-item ms-5" *ngIf="shouldShowSearch">
        <app-search-box class="z-1" (searchParameters)="setSearchParameters($event)"></app-search-box>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
        <a class="nav-link fs-5" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
          <i class="bi bi-person-circle"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" *ngIf="adminViewPanel" [routerLink]="['/admin']"
            routerLinkActive="active" (click)="hideNavigation()">
            <img src="../../../assets/images/icons/gitlab-icon.svg" alt="admin-icon" width="20" height="20"
              class="p-0" /> Admin
          </a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" *ngIf="teamLeadIntercomReviewView"
            [routerLink]="['/review']" routerLinkActive="active" (click)="hideNavigation()">
            <i class="bi bi-star-fill me-2"></i>Intercom Review
          </a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" *ngIf="intercomPartnerEscalationsRead"
            [routerLink]="['/partner-escalations']" routerLinkActive="active" (click)="hideNavigation()">
            <i class="bi bi-file-earmark-bar-graph-fill me-2"></i>Intercom Partner Escalations
          </a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" *ngIf="wabaUserManagementView"
            [routerLink]="['/waba-user-management']" routerLinkActive="active" (click)="hideNavigation()">
            <i class="bi bi-person-lines-fill me-2"></i>Waba User Management Audit
          </a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" (click)="openModal(preferenceModal)"
            *ngIf="user?.id">
            <i class="bi bi-sliders"></i>
            Preference
          </a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()" (click)="logout()">
            <img src="../../../assets/images/icons/log-out.svg" alt="log-out" />
            Logout
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<ng-template #preferenceModal let-modal>
  <div class="modal-header">
    <div class="d-inline-flex align-items-center">
      <h4 class="modal-title me-2">Preferences</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label>Phone Number</label>
        <input type="text" class="form-control" formControlName="phone_number">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-modal-proceed" type="submit" [disabled]="!this.form.valid"
      (click)="updateUserById()">
      Save
    </button>
    <button type="button" class="btn-modal-close" (click)="modal.close()">
      Close
    </button>
  </div>
</ng-template>