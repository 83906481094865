import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CacheService } from './services/cache/cache.service';
import { HistoryService } from './services/history/history.service';
import { SharedDataService } from './services/shared-data/shared-data.service';
import { CsToolApiService } from './services/cs-tool-api/cs-tool-api.service';
import { OboToSharedService } from './services/obo-to-shared/obo-to-shared.service';
import { DatabricksService } from './services/databricks/databricks.service';
import { JiraService } from './services/jira.service';
import { IntercomService } from './services/intercom.service';
import { PltAPIService } from './services/plt.api.service';
import { SendgridService } from './services/sendgrid.service';
import { StackService } from './services/stack.service';
import { PbsService } from './services/pbs.service';
import { InsightService } from './services/insight.service';
import { HubService } from './services/hub.service';
import { OnPremiseService } from './services/on.premise.service';
import { CloudService } from './services/cloud.service';
import { CustomRequestService } from './services/custom.request.service';
import { UserService } from './services/user.service';
import { WANotificationGroupService } from './services/wa-notification-group.service';
import { InstatusService } from './services/instatus.service';
import { TPPAuthXService } from './services/tpp-authx.service';
import { WabaUserManagementAuditService } from './services/waba-user-management-audit.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    HubService,
    CacheService,
    HistoryService,
    SharedDataService,
    CsToolApiService,
    OboToSharedService,
    DatabricksService,
    JiraService,
    IntercomService,
    PltAPIService,
    SendgridService,
    StackService,
    PbsService,
    InsightService,
    OnPremiseService,
    CloudService,
    CustomRequestService,
    UserService,
    WANotificationGroupService,
    InstatusService,
    TPPAuthXService,
    WabaUserManagementAuditService,
  ],
})
export class CoreModule {}
